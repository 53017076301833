// 我的问题工单
// mxy
<template>
  <div class="main flex-column" :style="{ minHeight: minHeight + 'px' }">
    <LeftOutlined class="back" @click="back" />
    <div class="pageHead">
      <span>工单号{{ orderCode.orderCode }}反馈记录</span>
    </div>
    <div class="middle">
      <a-spin :spinning="spinning">
        <div>
          <a-textarea
            class=""
            v-if="orderValid"
            v-model:value="value"
            showCount
            placeholder="请输入您想咨询的问题"
            :maxlength="200"
            allowClear
          />
          <a-button
            v-if="orderValid"
            @click="publish"
            class="publish"
            type="primary"
            >发表</a-button
          >
        </div>
        <!-- <div style="margin-top: 40px"> -->
        <div class="total">当前有{{ feedbackData.length }}条反馈</div>
        <div style="margin-top: 40px" :key="item" v-for="item in feedbackData">
          <a-empty v-if="item.length" />

          <div class="flex information">
            <!-- <div class="logo">
            <img :src="item.creatorAvatarUrl" alt="" />
          </div> -->
            <div class="logo">
              <img :src="item.creatorAvatarUrl" alt="" />
            </div>
            <div class="right">
              <div>
                <span>{{ item.creatorName }}</span>
                <span style="color: #bbb; margin-left: 4px">
                  {{
                    item.creationTime.split("T")[0] +
                    "  " +
                    item.creationTime.split("T")[1].split(".")[0]
                  }}</span
                >
              </div>
              <div class="describe">
                描述：
                <span>{{ item.content }} </span>
              </div>
              <div :key="i" v-for="i in item.answer">
                <span class="customer"> 客服</span
                ><span>回复：</span>
                <span style="margin-left: 4px">{{ i.content }}</span>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../../components/sameVariable'
import { LeftOutlined } from '@ant-design/icons-vue'
// import PageHead from '@/components/PageHead'
// import { isEmpty } from '@/assets/common.js'
// import { getTableColumnData } from '@/api/tableAbout/tableAPI'

export default defineComponent({
  components: {
    // 'page-head': PageHead,
    LeftOutlined,
  },
  data () {
    return {
      orderCode: {},
      value: '',
      feedbackData: [],
      spinning: false,
      orderValid: true,
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.orderCode = this.$route.query
    this.getOrderState()
    this.getFeedback()
  },
  // 方法
  methods: {
    getOrderState () {
      this.spinning = true
      api
        .get('/api/app/sys-problem-order/sys-problem-order', {
          params: {
            Id: this.$route.query.id,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.orderValid = (data.orderStateName !== '已关闭')
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取数据失败')
          console.log(err)
        })
    },
    // 获取反馈信息
    getFeedback () {
      this.spinning = true
      api
        .get('/api/app/sys-problem-order-question/question-list', {
          params: {
            Id: this.orderCode.id,
          },
        })
        .then(({ data }) => {
          this.feedbackData = data
          // console.log(this.feedbackData, 'this.feedbackData')
          this.spinning = false
          this.value = ''
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取工单状态数据失败')
          console.log(err)
        })
        .finally(() => {
          this.spinning = false
        })
    },
    back () {
      this.$router.push('/UserManagement/MyQuestions/WorkOrder')
    },
    // 发表
    publish () {
      this.spinning = true
      if (this.value === '') {
        this.$message.warning('请输入您想咨询的问题')
        this.spinning = false
        return
      }
      api
        .post('/api/app/sys-problem-order-question/question-publish', {
          content: this.value,
          id: this.orderCode.id,
        })
        .then(({ data }) => {
          this.getFeedback()
        })
        .catch(err => {
          // this.$message.error('获取工单状态数据失败')
          console.log(err)
        })
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";

.main {
  position: relative;
  background: #fff;
}
.feedback {
  position: absolute;
  right: 20px;
  top: 16px;
}
.pageHead {
  width: 100%;
  height: 60px;
  font-size: 18px;
  line-height: 60px;
  padding: 0 40px;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
}
.back {
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 23px;
  z-index: 999;
}
.middle {
  width: 100%;
  padding: 20px;
  // height: 80px;
  position: relative;
  .publish {
    position: absolute;
    right: 0px;
    margin-top: 20px;
  }
  .total {
    color: #919191;
    margin-bottom: 30px;
    font-size: 16px;
  }
  .information {
    padding: 20px 0;
    border-bottom: 1px solid #e3e3e3;
  }
  .logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: pink;
    overflow: hidden;
    margin-right: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    .describe {
      margin: 10px 0 14px 0;
    }
    .customer {
      color: blue;
      margin-right: 4px;
    }
  }
}
::v-deep(textarea.ant-input) {
  min-height: 100px;
}
</style>
